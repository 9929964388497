<template>
  <div>
    学生知
    学生へのお知らせ
  </div>
</template>

<script>
export default {
  name: "stuKonw"
}
</script>

<style scoped>

</style>
